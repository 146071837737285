import {createApp} from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import VueAxios from 'vue-axios'
import ElementPlus from 'element-plus'
import VueGtag from "vue-gtag-next";

import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import './index.css'
import './output.css'

const app = createApp(App);

app.use(ElementPlus)
   .use(VueAxios, axios)
   .use(router)
   .use(VueGtag, {
       property: {
           id: "G-EQV68BL2E4"
       }
   })
   .mount('#app');