import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-411b3e58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"padding-top":"15px"} }
const _hoisted_2 = { style: {"display":"flex","align-items":"center","justify-items":"space-between","height":"16px"} }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = {
  key: "{post.id}",
  class: "flex"
}
const _hoisted_5 = { class: "w-3/5 flex flex-col justify-center" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "text-blue-dark dark:text-white line-clamp-2" }
const _hoisted_8 = { class: "text-xs font-extralight text-blue dark:text-blue-medium" }
const _hoisted_9 = { class: "w-2/5 relative drop-shadow-xl shadow-gray overflow-hidden h-28" }
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!
  const _component_PostControlPanel = _resolveComponent("PostControlPanel")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!

  return (_openBlock(), _createBlock(_component_el_skeleton, {
    loading: !this.userPost,
    animated: ""
  }, {
    template: _withCtx(() => [
      _createVNode(_component_el_skeleton_item, {
        variant: "image",
        style: {"width":"100%","height":"160px"}
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_skeleton_item, { variant: "h3" }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_skeleton_item, { variant: "text" })
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userPost.post.tags, (tag) => {
              return (_openBlock(), _createElementBlock("span", {
                key: tag.id,
                class: "non-selectable post-tag text-xs text-sea dark:text-blue uppercase",
                type: "info",
                effect: "plain",
                onClick: ($event: any) => (_ctx.tagSelected(tag.name))
              }, _toDisplayString(tag.name), 9, _hoisted_6))
            }), 128)),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.userPost.post.title), 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.userPost.post.creationTimeAgo), 1),
            (_ctx.userAuthenticated)
              ? (_openBlock(), _createBlock(_component_PostControlPanel, {
                  key: 0,
                  "user-post": _ctx.userPost
                }, null, 8, ["user-post"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("img", {
              src: 
                _ctx.userPost.post.previewImageUrl
                  ? _ctx.userPost.post.previewImageUrl
                  : _ctx.userPost.post.defaultImage
              ,
              class: "absolute top-0 left-0 w-full h-auto",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openPost && _ctx.openPost(...args))),
              onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onError && _ctx.onError(...args)))
            }, null, 40, _hoisted_10)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}