
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { PropType } from "vue";
import { postEmitter, STATUS_UPDATED } from "@/events/PostEventBus";
import PostTextBottom from "@/components/post/PostTextBottom.vue";
import PostTextLeft from "@/components/post/PostTextLeft.vue";

import SectionTitle from "@/components/commons/SectionTitle.vue";
import { UserPostEntity } from "@/model/entities/UserPostEntity";
import PostCarousel from "@/components/post/PostCarousel.vue";
import { PostHttpService } from "@/server/services/impl/PostHttpService";

@Options({
  components: { SectionTitle, PostCarousel, PostTextBottom, PostTextLeft },
})
export default class PopularPostBoard extends Vue {
  private postService: PostHttpService = new PostHttpService();

  @Prop({ type: Object as PropType<URLSearchParams> })
  private requestedOptions!: URLSearchParams;
  //Init with empty posts for displaying loading skeleton
  private posts: UserPostEntity[] = new Array(5).fill(null);

  public mounted(): void {
    const options = new URLSearchParams({"period": "LAST_TWO_MONTHS"});
    postEmitter.on(STATUS_UPDATED, () => this.loadPopularPosts(options));
    this.loadPopularPosts(options);
  }

  private periodChanged(period) {
    const options = new URLSearchParams(this.requestedOptions);
    options.set("period", period.toString());
    this.loadPopularPosts(options);
  }

  @Watch("requestedOptions")
  private optionsChanged(): void {
    this.loadPopularPosts(this.requestedOptions);
  }

  private loadPopularPosts(options?: URLSearchParams): void {
    this.postService
      .loadPosts({ page: 1, size: 20, popular: true, listId: null }, options)
      .then((response) => {
        this.posts = response.data.content;
      });
  }
}
