
import { Options, Vue } from "vue-class-component";
import { ProvideReactive } from "vue-property-decorator";
import { useAuth } from "./services/auth";
import { useDark, useToggle } from "@vueuse/core";
import WeatherSunny from "vue-material-design-icons/WeatherSunny.vue";
import WeatherNight from "vue-material-design-icons/WeatherNight.vue";
import CookiePolicy from "@/components/CookiePolicy.vue";
import Menu from "@/components/Menu.vue"

@Options({ components: { App, CookiePolicy, WeatherSunny, WeatherNight, Menu } })
export default class App extends Vue {
  @ProvideReactive()
  private authenticated = false;
  @ProvideReactive()
  private roles = new Array<string>();
  private picture: String = "";
  private username: String = "";
  private currentPath = window.location.pathname;
  private adminRoutes = ['/emails', '/postlist', '/channels', '/category/management', '/statistics'];

  public isDark = useDark();
  toggleDark = useToggle(this.isDark);

  async created() {
    this.authenticated = await useAuth().isAuthenticated();
    const user = await useAuth().getUser();
    this.roles = user?.roles as Array<string>;
    this.username = user?.username as String;
    this.picture = user?.picture as String;
  }

  private hasRole(role: string): Boolean {
    return useAuth().hasRole(role);
  }

  public shouldShrinkImage() {
    return !(this.$route.path === '/dashboard' && Object.keys(this.$route.query).length === 0);
  }

  public shouldShowImage() {
    return !this.adminRoutes.includes(this.$route.path);
  }
}
