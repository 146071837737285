import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "recommended",
  className: "max-w-6xl mx-4 lg:mx-auto my-16"
}
const _hoisted_2 = { class: "recommendations-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_PostTextRight = _resolveComponent("PostTextRight")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SectionTitle, {
        title: "Recommended",
        "enable-change-layout": false,
        "enable-period-select": false,
        "query-object": {tags: (this.$route.query.tags) ? this.$route.query.tags : '', recommended: true},
        "router-link": "/board",
        onPeriodChanged: _ctx.periodChanged
      }, null, 8, ["query-object", "onPeriodChanged"])
    ]),
    (_ctx.posts.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.posts.slice(0, 2), (post, index) => {
          return (_openBlock(), _createBlock(_component_PostTextRight, {
            key: index,
            "user-post": post
          }, null, 8, ["user-post"]))
        }), 128))
      : _createCommentVNode("", true)
  ]))
}