import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3675b052"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "latest",
  className: "max-w-6xl mx-4 lg:mx-auto my-16"
}
const _hoisted_2 = { id: "latest-post-container" }
const _hoisted_3 = { className: "grid grid-cols-2 md:grid-cols-3 gap-3 md:gap-4" }
const _hoisted_4 = { class: "pagination-wrapper mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_PostTextBottomWithWrapper = _resolveComponent("PostTextBottomWithWrapper")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_ctx.totalPostCount)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SectionTitle, {
            title: "Latest Posts",
            "enable-change-layout": true,
            "enable-period-select": false,
            "query-object": {
          tags: this.$route.query.tags ? this.$route.query.tags : '',
        },
            "router-link": "/board",
            layout: _ctx.layout,
            onLayoutChanged: _cache[0] || (_cache[0] = (layoutEvent) => (this.layout = layoutEvent)),
            onPeriodChanged: _ctx.periodChanged
          }, null, 8, ["query-object", "layout", "onPeriodChanged"]),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userPosts, (post, index) => {
              return (_openBlock(), _createBlock(_component_PostTextBottomWithWrapper, {
                key: index,
                "user-post": post
              }, null, 8, ["user-post"]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_pagination, {
              currentPage: _ctx.currentPageNumber,
              "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPageNumber) = $event)),
              "page-size": _ctx.currentPageSize,
              "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentPageSize) = $event)),
              "page-sizes": _ctx.pageSizes,
              size: "small",
              layout: "prev, pager, next, sizes",
              total: _ctx.totalPostCount,
              onSizeChange: _ctx.loadCurrentPage,
              onCurrentChange: _ctx.onCurrentPageChange,
              class: "flex-wrap bg-blue-medium text-blue-dark dark:text-gray"
            }, null, 8, ["currentPage", "page-size", "page-sizes", "total", "onSizeChange", "onCurrentChange"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}