
import { Vue } from "vue-class-component";
import { ProvideReactive } from "vue-property-decorator";
import { useAuth } from "../services/auth";
import { useWindowSize } from '@vueuse/core'


export default class Menu extends Vue {
  @ProvideReactive()
  private authenticated = false;
  @ProvideReactive()
  

  private currentPath = window.location.pathname;

  async created() {
    this.authenticated = await useAuth().isAuthenticated();
  }

  private hasRole(role: string): Boolean {
    return useAuth().hasRole(role);
  }

  public menuMode() {
    const { width } = useWindowSize()

    return width.value > 767 ? 'vertical' : 'horizontal';
  }


}
