import axios from "axios";

export const server = axios.create({
  //withCredentials: true,
  baseURL: `${
    process.env.NODE_ENV === "development"
      ? `https://newsletter-dev.exadel.com/`
      : "/"
  }`,
  validateStatus: (status) => {
    return status >= 200 && status <= 304;
  },
});

server.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);
