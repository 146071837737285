
import {Options, Vue} from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import {PropType} from "vue";
import 'vue3-carousel/dist/carousel.css';
import {postEmitter, STATUS_UPDATED} from "@/events/PostEventBus";
import SectionTitle from "@/components/commons/SectionTitle.vue";
import {UserPostEntity} from "@/model/entities/UserPostEntity";
import PostCarousel from "@/components/post/PostCarousel.vue";
import {PostHttpService} from "@/server/services/impl/PostHttpService";
import PostTextRight from "@/components/post/PostTextRight.vue";

@Options({components: {SectionTitle, PostCarousel, PostTextRight}})
export default class RecommendedPostBoard extends Vue {
    private postService: PostHttpService = new PostHttpService();

    @Prop({type: Object as PropType<URLSearchParams>})
    private requestedOptions!: URLSearchParams;
    //Init with empty posts for displaying loading skeleton
    private posts: UserPostEntity[] = new Array(5).fill(null);

    public mounted(): void {
        postEmitter.on(STATUS_UPDATED, () => this.loadRecommendedPosts())
        this.loadRecommendedPosts();
    }

    private periodChanged(period) {
        const options = new URLSearchParams(this.requestedOptions);
        options.set("period", period.toString());
        this.loadRecommendedPosts(options);
    }

    @Watch('requestedOptions')
    private optionsChanged(): void {
        this.loadRecommendedPosts(this.requestedOptions);
    }

    private loadRecommendedPosts(params?: URLSearchParams): void {
        this.postService.loadPosts({page: 1, size: 20, recommended: true, listId: null}, params)
            .then((response) => this.posts = response.data.content)
    }
}
