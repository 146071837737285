import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"min-width":"100%"} }
const _hoisted_2 = { style: {"display":"flex","justify-content":"space-between","align-items":"center","flex-wrap":"wrap"} }
const _hoisted_3 = ["src"]
const _hoisted_4 = { style: {"margin-left":"10px"} }
const _hoisted_5 = { class: "dialog-footer" }
const _hoisted_6 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_EmailModuleItem = _resolveComponent("EmailModuleItem")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_EmailTemplateTable = _resolveComponent("EmailTemplateTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_breadcrumb, { style: {"margin-bottom":"15px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_el_breadcrumb_item, { to: { name: 'EmailBuilder', query: {active: 'emails'} } }, {
          default: _withCtx(() => [
            _createTextVNode("emails")
          ]),
          _: 1
        }),
        (_ctx.email.id)
          ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, { key: 0 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.email.id), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.email.id)
          ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, { key: 1 }, {
              default: _withCtx(() => [
                _createTextVNode("update email")
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_el_breadcrumb_item, { key: 2 }, {
              default: _withCtx(() => [
                _createTextVNode("create email")
              ]),
              _: 1
            }))
      ]),
      _: 1
    }),
    _createVNode(_component_el_form, {
      model: _ctx.email,
      "label-width": "auto"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, { style: {"margin-bottom":"8px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              plain: "",
              onClick: _ctx.saveEmail
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Create Email ")
              ]),
              _: 1
            }, 8, ["onClick"]),
            (_ctx.email.id)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  type: "primary",
                  plain: "",
                  onClick: _ctx.updateEmail
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Update email ")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_el_button, {
              type: "primary",
              plain: "",
              onClick: _ctx.sendEmail
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Send email ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "Name",
          prop: "name",
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.email.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email.name) = $event)),
              class: "m-2",
              "show-word-limit": "",
              maxlength: "50"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "Delivery email",
          prop: "email",
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.email.email,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email.email) = $event)),
              class: "m-2",
              "show-word-limit": "",
              maxlength: "50"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              plain: "",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.templateDialogVisible = !_ctx.templateDialogVisible))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Select Template ")
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.email.template.modules, (description, index) => {
          return (_openBlock(), _createBlock(_component_el_form_item, { key: description }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_EmailModuleItem, {
                  "module-description": description,
                  index: index,
                  showDeleteButton: false,
                  onModuleUpdate: _ctx.updatePreview
                }, null, 8, ["module-description", "index", "onModuleUpdate"])
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_el_dialog, {
      title: "Choose posts",
      width: "1000px",
      modelValue: _ctx.postDialogVisible,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.postDialogVisible) = $event)),
      onOpen: _ctx.loadPosts
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_button, {
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.postDialogVisible = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.confirmSelection
          }, {
            default: _withCtx(() => [
              _createTextVNode("Confirm")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_table, {
              data: _ctx.postPage.content,
              "empty-text": "No posts found.",
              onSelectionChange: _ctx.onSelection
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  type: "selection",
                  width: "30"
                }),
                _createVNode(_component_el_table_column, { label: "Name" }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_el_link, { underline: false }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            src: (scope.row.post.previewImageUrl) ? scope.row.post.previewImageUrl : scope.row.post.defaultImage,
                            class: "image",
                            height: "40"
                          }, null, 8, _hoisted_3),
                          _createElementVNode("span", _hoisted_4, _toDisplayString(scope.row.post.title), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createElementVNode("div", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.post.tags.map(tag => tag.name), (tagName) => {
                          return (_openBlock(), _createBlock(_component_el_tag, {
                            type: "info",
                            effect: "plain",
                            style: {"margin-right":"3px"}
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(tagName), 1)
                            ]),
                            _: 2
                          }, 1024))
                        }), 256)),
                        _createVNode(_component_el_tag, {
                          effect: "plain",
                          style: {"margin-right":"3px"}
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(scope.row.post.creationTimeAgo), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data", "onSelectionChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_pagination, {
              currentPage: _ctx.postSearchCriteria.page,
              "onUpdate:currentPage": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.postSearchCriteria.page) = $event)),
              "page-size": _ctx.postSearchCriteria.size,
              "onUpdate:pageSize": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.postSearchCriteria.size) = $event)),
              "page-sizes": _ctx.pageSizes,
              size: "small",
              layout: "prev, pager, next, sizes",
              total: _ctx.postPage.totalItemsCount,
              onSizeChange: _ctx.loadPosts,
              onCurrentChange: _ctx.loadPosts,
              class: "flex-wrap dark:text-gray"
            }, null, 8, ["currentPage", "page-size", "page-sizes", "total", "onSizeChange", "onCurrentChange"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "onOpen"]),
    _createVNode(_component_el_dialog, {
      title: "Choose template",
      width: "600px",
      modelValue: _ctx.templateDialogVisible,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.templateDialogVisible) = $event)),
      onOpen: _ctx.loadAvailableTemplates
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_6, [
          _createVNode(_component_el_button, {
            type: "danger",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.templateDialogVisible = !_ctx.templateDialogVisible))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_EmailTemplateTable, {
              manageable: false,
              "single-select": true,
              onRowSelected: _ctx.handleSelectedTemplate
            }, null, 8, ["onRowSelected"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "onOpen"])
  ], 64))
}