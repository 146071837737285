import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-sea sm:ml-auto md:ml-2 lg:ml-3 mr-0 md:mr-auto lg:mr-2 uppercase font-medium top-header-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_menu, {
      "default-active": this.$route.path,
      class: "main-menu bg-transparent text-lg border-0 dark:text-white",
      router: true,
      mode: _ctx.menuMode(),
      "active-text-color": "text-sea"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_menu_item, {
          index: "/dashboard",
          class: _normalizeClass(["text-sea text-lg", this.currentPath.includes('dashboard') ? 'menu-underline' : '' ])
        }, {
          default: _withCtx(() => [
            _createTextVNode("Dashboard")
          ]),
          _: 1
        }, 8, ["class"]),
        (_ctx.authenticated && _ctx.hasRole('ROLE_USER'))
          ? (_openBlock(), _createBlock(_component_el_menu_item, {
              key: 0,
              index: "/postlist",
              class: _normalizeClass(this.currentPath.includes('postlist') ? 'menu-underline' : '' )
            }, {
              default: _withCtx(() => [
                _createTextVNode("Reading Lists")
              ]),
              _: 1
            }, 8, ["class"]))
          : _createCommentVNode("", true),
        (_ctx.authenticated && _ctx.hasRole('ROLE_EDITOR'))
          ? (_openBlock(), _createBlock(_component_el_menu_item, {
              key: 1,
              index: "/emails",
              class: _normalizeClass(this.currentPath.includes('emails') ? 'menu-underline' : '' )
            }, {
              default: _withCtx(() => [
                _createTextVNode("Emails")
              ]),
              _: 1
            }, 8, ["class"]))
          : _createCommentVNode("", true),
        (_ctx.authenticated && _ctx.hasRole('ROLE_ADMIN'))
          ? (_openBlock(), _createBlock(_component_el_menu_item, {
              key: 2,
              index: "/channels",
              class: _normalizeClass(this.currentPath.includes('channels') ? 'menu-underline' : '' )
            }, {
              default: _withCtx(() => [
                _createTextVNode("Channels")
              ]),
              _: 1
            }, 8, ["class"]))
          : _createCommentVNode("", true),
        (_ctx.authenticated && _ctx.hasRole('ROLE_ADMIN'))
          ? (_openBlock(), _createBlock(_component_el_menu_item, {
              key: 3,
              index: "/category/management",
              class: _normalizeClass(this.currentPath.includes('category/management') ? 'menu-underline' : '' )
            }, {
              default: _withCtx(() => [
                _createTextVNode("Category management")
              ]),
              _: 1
            }, 8, ["class"]))
          : _createCommentVNode("", true),
        (_ctx.authenticated && _ctx.hasRole('ROLE_ADMIN'))
          ? (_openBlock(), _createBlock(_component_el_menu_item, {
              key: 4,
              index: "/statistics",
              class: _normalizeClass(this.currentPath.includes('statistics') ? 'menu-underline' : '' )
            }, {
              default: _withCtx(() => [
                _createTextVNode("Statistics")
              ]),
              _: 1
            }, 8, ["class"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["default-active", "mode"])
  ]))
}