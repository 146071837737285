
import {Options, Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {PropType} from "vue";
import {UserPostEntity} from "@/model/entities/UserPostEntity";
import PostTextBottomWithWrapper from "./PostTextBottomWithWrapper.vue";

@Options({components: {PostTextBottomWithWrapper}})
export default class PostGrid extends Vue {

    @Prop({type: Object as PropType<Array<UserPostEntity>>})
    private posts: Array<UserPostEntity> = [];

    @Prop({type: String as PropType<String>})
    private elementLoadingText = "";

    @Prop({type: Boolean as PropType<Boolean>})
    private loading: Boolean = false;

    @Prop({type: String as PropType<String>})
    private emptyContentPlaceholder = "";
}
