import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-935bbf2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "empty-content-placeholder-container"
}
const _hoisted_2 = { class: "empty-content-placeholder" }
const _hoisted_3 = {
  key: 1,
  class: "grid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PostTextBottomWithWrapper = _resolveComponent("PostTextBottomWithWrapper")!
  const _component_el_row = _resolveComponent("el-row")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_el_row, { "element-loading-text": _ctx.elementLoadingText }, {
    default: _withCtx(() => [
      (!_ctx.posts.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.emptyContentPlaceholder), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.posts.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
              return (_openBlock(), _createBlock(_component_PostTextBottomWithWrapper, {
                key: post,
                userPost: post
              }, null, 8, ["userPost"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["element-loading-text"])), [
    [_directive_loading, _ctx.loading]
  ])
}